/**
 * コモンクラス
 * @constructor
 */
class Common {
  constructor() {

  }
  /**
   * コモン
   */
  init() {
    $(document).ready(function(){
      $(".page-top").hide();
      $(window).on("scroll", function() {
          if ($(this).scrollTop() > 100) {
              $(".page-top").fadeIn("fast");
          } else {
              $(".page-top").fadeOut("fast");
          }
      });
  });
  }

}

export default Common;
