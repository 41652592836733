var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-slider" },
    [
      _c(
        "swiper",
        { ref: "mySwiper", attrs: { options: _vm.swiperOption } },
        [
          _c("swiper-slide", [
            _c("img", {
              attrs: {
                src: "http://placehold.jp/24/cc9999/993333/1500x1000.png"
              }
            })
          ]),
          _vm._v(" "),
          _c("swiper-slide", [
            _c("img", {
              attrs: {
                src: "http://placehold.jp/24/cc9999/993333/1500x1000.png"
              }
            })
          ]),
          _vm._v(" "),
          _c("swiper-slide", [
            _c("img", {
              attrs: {
                src: "http://placehold.jp/24/cc9999/993333/1500x1000.png"
              }
            })
          ]),
          _vm._v(" "),
          _c("swiper-slide", [
            _c("img", {
              attrs: {
                src: "http://placehold.jp/24/cc9999/993333/1500x1000.png"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-pagination",
            attrs: { slot: "pagination" },
            slot: "pagination"
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-button-prev",
            attrs: { slot: "button-prev" },
            slot: "button-prev"
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-button-next",
            attrs: { slot: "button-next" },
            slot: "button-next"
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }